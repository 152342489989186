import {
    generateDateTimeConfig,
    generateFuzzyConfig,
    generateRangeConfig,
    generateSelectConfig,
    generateQueryConfig,
    generateExactRequiredConfig,
} from "generateFieldConfig";

const subObjectTypeKeys = [
    "arable_farm",
    "business_home",
    "car_wash",
    "cattle_farm",
    "commercial",
    "cultural_land",
    "development",
    "farm",
    "fattening_pigs",
    "fruit_growing",
    "fuel_station",
    "fuel_unmannend_gasstation",
    "fur_farm",
    "gastronomy",
    "goat_farm",
    "healthcare",
    "horeca",
    "horse_husbandry",
    "horticulture",
    "industrial",
    "land",
    "leisure",
    "livestock_farming",
    "logistics",
    "mixed",
    "mixed_use",
    "mushroom_company",
    "office",
    "other",
    "parking",
    "pig_farm",
    "poultry_farming",
    "residential",
    "retail",
    "rural_living",
    "self_storage",
    "socialrealestate",
    "tree_growing",
];
const subObjectTypeItems = subObjectTypeKeys.map((item) => {
    const objectTypeSubKey = `valuation_object_type_${item}`;
    const conditionValue = `valuation_object_type_val_${item}`;

    return generateSelectConfig(
        `property.${objectTypeSubKey}.description`,
        `data.string.${objectTypeSubKey}`,
        [],
        false,
        "data.string.valuation_object_type",
        [conditionValue],
        false,
        `property.${objectTypeSubKey}.values.`
    );
});

const compViewForm = {
    sales: [
        generateFuzzyConfig("address.City", "location.city", false, false, false, false, false, false, "text"),
        generateFuzzyConfig("address.Street", "location.street", false, false, false, false, false, false, "text"),
        generateSelectConfig(
            "property.valuation_object_type.description",
            "data.string.valuation_object_type",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "property.valuation_object_type.values."
        ),
        ...subObjectTypeItems,
        generateSelectConfig(
            "property.valuation_transaction_type.description",
            "data.string.valuation_transaction_type",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "property.valuation_transaction_type.values."
        ),

        generateDateTimeConfig(
            "property.valuation_date_valuation.transaction.description",
            "data.dateTime.valuation_date_valuation",
            true,
            false,
            false,
            false
        ),

        generateRangeConfig(
            "compview.form.valuation_value_ri_total",
            "data.double.valuation_value_ri_total",
            true,
            false,
            false,
            false,
            "currency"
        ),
        generateRangeConfig("compview.form.valuation_value_nla_total", "data.double.valuation_value_nla_total", true),

        generateRangeConfig(
            "compview.form.valuation_value_giy",
            "data.double.valuation_value_giy",
            true,
            false,
            false,
            false,
            "percentage"
        ),

        generateRangeConfig(
            "compview.form.transactionPrice",
            "data.double.valuation_marketability_purchaseprice",
            true,
            false,
            false,
            false,
            "currency"
        ),
        generateRangeConfig(
            "property.valuation_value_mv_sqm_land.description",
            "data.double.valuation_value_mv_sqm",
            true,
            false,
            false,
            false,
            "currency"
        ),

        generateRangeConfig("compview.form.wall", "data.double.valuation_value_expirydate", true, false, false, false),

        generateSelectConfig(
            "property.valuation_proposal_tenure.description",
            "data.string.object_landregistry_tenure",
            [],
            false,
            false,
            false,
            true,
            "property.object_landregistry_tenure.values."
        ),
    ],
    rental: [
        generateFuzzyConfig("address.City", "location.city", false, false, false, false, false, false, "text"),

        generateFuzzyConfig("address.Street", "location.street", false, false, false, false, false, false, "text"),

        generateSelectConfig(
            "property.valuation_object_type.description",
            "data.string.valuation_object_type",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "property.valuation_object_type.values."
        ),

        ...subObjectTypeItems,

        generateSelectConfig(
            "property.valuation_location_segment.description",
            "data.string.valuation_location_segment",
            [],
            false,
            "data.string.valuation_object_type",
            ["valuation_object_type_val_retail"],
            false,
            "property.valuation_location_segment.values."
        ),

        generateRangeConfig(
            "property.object_transaction_rental_tenant_nla.description",
            "data.double.object_transaction_rental_tenant_nla",
            true,
            false,
            false,
            false
        ),

        generateRangeConfig(
            "property.object_transaction_rental_tenant_ri.description",
            "data.double.object_transaction_rental_tenant_ri",
            true,
            false,
            false,
            false,
            "currency"
        ),
        generateRangeConfig(
            "property.object_transaction_rental_tenant_ri_sqm.description",
            "data.double.object_transaction_rental_tenant_ri_sqm",
            true,
            false,
            false,
            false,
            "currency"
        ),

        generateRangeConfig(
            "property.object_transaction_rental_tenant_a_ri_sqm.description",
            "data.double.object_transaction_rental_tenant_a_ri_sqm",
            false,
            false,
            "data.string.valuation_object_type",
            ["valuation_object_type_val_retail"],
            "currency"
        ),

        generateDateTimeConfig(
            "property.object_transaction_rental_tenant_startdate.description",
            "data.dateTime.object_transaction_rental_tenant_startdate",
            true,
            false,
            false,
            false,
            false,
            false,
            "text"
        ),
        generateDateTimeConfig(
            "property.object_transaction_rental_tenant_expirydate.description",
            "data.dateTime.object_transaction_rental_tenant_expirydate",
            true,
            false,
            false,
            false
        ),

        generateRangeConfig(
            "property.object_transaction_rental_tenant_leaseterm.description",
            "data.double.object_transaction_rental_tenant_leaseterm",
            true,
            false,
            false,
            false
        ),
        generateRangeConfig(
            "property.object_transaction_rental_tenant_rentfree.description",
            "data.double.object_transaction_rental_tenant_rentfree",
            true,
            false,
            false,
            false
        ),

        generateFuzzyConfig(
            "compview.form.object_transaction_rental_tenant",
            "data.string.object_transaction_rental_tenant",
            false,
            false,
            false,
            false,
            false,
            false,
            "text"
        ),
    ],
    valuation: [
        // city
        generateFuzzyConfig("address.City", "location.city", false, false, false, false, false, false, "text"),

        // street
        generateFuzzyConfig("address.Street", "location.street", false, false, false, false, false, false, "text"),

        // object type
        generateSelectConfig(
            "property.valuation_object_type.description",
            "data.string.valuation_object_type",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "property.valuation_object_type.values."
        ),

        // object subtype
        ...subObjectTypeItems,

        // segment
        generateSelectConfig(
            "property.valuation_location_segment.description",
            "data.string.valuation_location_segment",
            [],
            false,
            "data.string.valuation_object_type",
            ["valuation_object_type_val_retail"],
            false,
            "property.valuation_location_segment.values."
        ),

        // status
        generateSelectConfig("basic.status", "status", [], false, false, false, true, "status."),

        // valuation date
        generateDateTimeConfig(
            "property.valuation_date_valuation.description",
            "data.dateTime.valuation_date_valuation",
            true,
            false,
            false,
            false
        ),

        // date sent to client
        generateDateTimeConfig(
            "property.valuation_date_finished.description",
            "data.dateTime.valuation_date_finished",
            true,
            false,
            false,
            false
        ),

        // market value
        generateRangeConfig(
            "property.valuation_value_mv_amount.description",
            "data.double.valuation_value_mv_amount",
            true,
            false,
            false,
            false,
            "currency"
        ),

        // market value per sqm
        generateRangeConfig(
            "property.valuation_value_mv_sqm.description",
            "data.double.valuation_value_mv_sqm",
            true,
            false,
            false,
            false,
            "currency"
        ),

        // rental income
        generateRangeConfig(
            "property.valuation_value_ri_total.description",
            "data.double.valuation_value_ri_total",
            true,
            false,
            false,
            false,
            "currency"
        ),

        // yield(giy)
        generateRangeConfig(
            "compview.form.valuation_value_giy",
            "data.double.valuation_value_giy",
            true,
            false,
            false,
            false,
            "percentage"
        ),

        // building size
        generateRangeConfig("compview.form.valuation_value_nla_total", "data.double.valuation_value_nla_total", true),

        // wall
        generateRangeConfig("compview.form.wall", "data.double.valuation_value_expirydate", true, false, false, false),

        // fee
        generateRangeConfig(
            "property.valuation_proposal_fee_type.description",
            "data.double.valuation_proposal_fee",
            true,
            false,
            false,
            false,
            "currency"
        ),

        // year of construction
        generateExactRequiredConfig(
            "property.valuation_construction_year.description",
            "data.string.valuation_construction_year",
            false,
            false,
            false,
            false,
            false,
            false,
            "text"
        ),

        // Client
        generateQueryConfig("client.client", "client", false, false, false, false, false, false, "text"),

        // our reference - instruction number
        generateFuzzyConfig(
            "property.valuation_instructionnumber.description",
            "data.string.valuation_instructionnumber",
            false,
            false,
            false,
            false,
            false,
            false,
            "text"
        ),

        // valuation type
        generateSelectConfig(
            "property.valuation_type.description",
            "data.string.valuation_type",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "property.valuation_type.values."
        ),

        // date created
        generateDateTimeConfig("valuation.dateCreated", "dateCreated", true, false, false, false),

        // report type
        generateSelectConfig(
            "property.valuation_proposal_reporttype.description",
            "data.string.valuation_proposal_reporttype",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "property.valuation_proposal_reporttype.values."
        ),
    ],
    valuationApollo: [
        /*
v - Type of use
v - City
c - Zip code
v - Address
c - Property ID
c - Portfolio
v - Type of Value (Indication, Market Value, Mortgage Lending Value etc.)
v - Method of valuation (DCF, Ertragswert, Residual Valuation etc.)
v - Status of valuation (finished, contract, offer etc.)
c - GRY
v - NIY
c - GIY
c - NRY
v - WAULT
v - Multiplier on Current Rent
v - Multiplier on Market Rent
v - Lettable area
v - Date of valuation
v - Value in € (Market Value)
XX - Value per sq m
v - Occupancy rate
v - Year of construction
x - Year of refurbishment
v - Current Rent p.a. (total)
v - Current Rent per sq m per month
v - Market Rent p.a. (total)
 Current Rent per sq m per month
x - Tenant names
v - Client
v - Valuer
        */
        generateSelectConfig(
            "property.valuation_object_type.description",
            "data.string.valuation_object_type",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "property.valuation_object_type.values."
        ),
        ...subObjectTypeItems,
        generateFuzzyConfig("address.City", "location.city", false, false, false, false, false, false, "text"),
        generateExactRequiredConfig(
            "address.Zip",
            "location.postal_code",
            false,
            false,
            false,
            false,
            false,
            false,
            "text"
        ),
        generateFuzzyConfig("address.Street", "location.street", false, false, false, false, false, false, "text"),
        generateFuzzyConfig(
            "property.valuation_external_id_modeltree_asset.description",
            "data.string.valuation_external_id_modeltree_asset",
            false,
            false,
            false,
            false,
            false,
            false,
            "text"
        ),
        generateQueryConfig("portfolio.portfolio", "portfolio.name", false, false, false, false, false, false),
        generateSelectConfig(
            "property.valuation_value.description",
            "data.string.valuation_value",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "property.valuation_value.values."
        ),
        generateSelectConfig(
            "property.valuation_methodology.description",
            "data.string.valuation_methodology",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "property.valuation_methodology.values."
        ),
        generateSelectConfig("basic.status", "status", [], false, false, false, true, "status."),
        generateRangeConfig(
            "property.valuation_value_giy_kk_acorr_ri.description",
            "data.double.valuation_value_giy_kk_acorr_ri",
            true,
            false,
            false,
            false,
            "percentage"
        ),
        generateRangeConfig(
            "property.valuation_value_niy.description",
            "data.double.valuation_value_niy",
            true,
            false,
            false,
            false,
            "percentage"
        ),
        generateRangeConfig(
            "property.valuation_value_giy_kk_acorr_erv.description",
            "data.double.valuation_value_giy_kk_acorr_erv",
            true,
            false,
            false,
            false,
            "percentage"
        ),
        generateRangeConfig(
            "property.valuation_value_niy_onmarketrent.description",
            "data.double.valuation_value_niy_onmarketrent",
            true,
            false,
            false,
            false,
            "percentage"
        ),
        generateRangeConfig(
            "property.valuation_value_expirydate.description",
            "data.double.valuation_value_expirydate",
            true,
            false,
            false,
            false
        ),
        generateRangeConfig(
            "property.valuation_value_multi_kk_acorr_ri.description",
            "data.double.valuation_value_multi_kk_acorr_ri",
            true,
            false,
            false,
            false
        ),
        generateRangeConfig(
            "property.valuation_value_multi_kk_acorr_erv.description",
            "data.double.valuation_value_multi_kk_acorr_erv",
            true,
            false,
            false,
            false
        ),
        generateRangeConfig(
            "property.valuation_value_nla_total.description",
            "data.double.valuation_value_nla_total",
            true,
            false,
            false,
            false
        ),
        generateDateTimeConfig(
            "property.valuation_date_valuation.description",
            "data.dateTime.valuation_date_valuation",
            true,
            false,
            false,
            false
        ),
        generateRangeConfig(
            "property.valuation_value_mv_amount.description",
            "data.double.valuation_value_mv_amount",
            true,
            false,
            false,
            false,
            "currency"
        ),
        // generateRangeConfig(
        //     "property.valuation_value_mv_sqm.description",
        //     "data.double.valuation_value_mv_sqm",
        //     true,
        //     false,
        //     false,
        //     false,
        //     "currency"
        // ),
        generateRangeConfig(
            "property.valuation_value_vacancy_rate.description",
            "data.double.valuation_value_vacancy_rate",
            true,
            false,
            false,
            false,
            "percentage"
        ),
        generateExactRequiredConfig(
            "property.valuation_construction_year.description",
            "data.string.valuation_construction_year",
            false,
            false,
            false,
            false,
            false,
            false,
            "text"
        ),
        generateExactRequiredConfig(
            "property.valuation_construction_renovation_year_desc.description",
            "data.string.valuation_construction_renovation_year_desc",
            false,
            false,
            false,
            false,
            false,
            false,
            "text"
        ),
        generateRangeConfig(
            "property.valuation_value_ri_total.description",
            "data.double.valuation_value_ri_total",
            true,
            false,
            false,
            false,
            "currency"
        ),
        generateRangeConfig(
            "property.valuation_value_tenant_ri_overview_total_passing_rents_sqm_mth.description",
            "data.double.valuation_value_tenant_ri_overview_total_passing_rents_sqm_mth",
            true,
            false,
            false,
            false,
            "currency"
        ),
        generateRangeConfig(
            "property.valuation_value_erv_incl_erv_vacancy.description",
            "data.double.valuation_value_erv_incl_erv_vacancy",
            true,
            false,
            false,
            false,
            "currency"
        ),
        // TODO Tenant names
        generateQueryConfig("client.client", "client", false, false, false, false, false, false, "text"),
        generateQueryConfig(
            "property.valuation_appraisers_first.description",
            "data.user.valuation_appraisers_first.profile.username",
            false,
            false,
            false,
            false,
            false,
            false,
            "text"
        ),
    ],
    brainbayAgriculture: [
        generateSelectConfig(
            "brainbay.filter.companyType.label",
            "brainbay.companyType",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.companyType.values."
        ),

        generateSelectConfig(
            "brainbay.filter.province.label",
            "brainbay.province",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.province.values."
        ),

        generateSelectConfig(
            "brainbay.filter.status.label",
            "brainbay.status",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.status.values."
        ),

        generateDateTimeConfig(
            "brainbay.filter.transactionDate.label",
            "brainbay.transactionDate",
            true,
            false,
            false,
            false
        ),

        generateRangeConfig(
            "brainbay.filter.parcelSize.label",
            "brainbay.parcelSize",
            true,
            false,
            false,
            false,
            "number"
        ),
        generateRangeConfig(
            "brainbay.filter.salePrice.label",
            "brainbay.salePrice",
            true,
            false,
            false,
            false,
            "currency"
        ),

        generateDateTimeConfig("brainbay.filter.auctionDate.label", "brainbay.auctionDate", true, false, false, false),

        generateFuzzyConfig(
            "brainbay.filter.zoekterm.label",
            "location.zoekterm",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),
        generateSelectConfig(
            "brainbay.filter.ignoreLatLon.label",
            "brainbay.ignoreLatLon",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),
        generateFuzzyConfig(
            "brainbay.filter.postalCodeFrom.label",
            "brainbay.postalCodeFrom",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),
        generateFuzzyConfig(
            "brainbay.filter.postalCodeTo.label",
            "brainbay.postalCodeTo",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),
    ],
    brainbayBusiness: [
        generateFuzzyConfig(
            "brainbay.filter.postalCodeFrom.label",
            "brainbay.postalCodeFrom",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),
        generateFuzzyConfig(
            "brainbay.filter.postalCodeTo.label",
            "brainbay.postalCodeTo",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),

        generateSelectConfig(
            "brainbay.filter.status.label",
            "brainbay.status",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.status.values."
        ),

        generateSelectConfig(
            "brainbay.filter.mainPurpose.label",
            "brainbay.mainPurpose",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.mainPurpose.values."
        ),

        generateSelectConfig(
            "brainbay.filter.companyType.label",
            "brainbay.companyType",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.companyType.values."
        ),

        generateDateTimeConfig(
            "brainbay.filter.transactionDate.label",
            "brainbay.transactionDate",
            true,
            false,
            false,
            false
        ),

        generateRangeConfig(
            "brainbay.filter.officeArea.label",
            "brainbay.officeArea",
            true,
            true,
            "brainbay.companyType",
            ["Kantoorruimte"],
            "number"
        ),
        generateRangeConfig(
            "brainbay.filter.companyHallArea.label",
            "brainbay.companyHallArea",
            true,
            false,
            false,
            false,
            "number"
        ),
        generateRangeConfig(
            "brainbay.filter.companyOfficeHallArea.label",
            "brainbay.companyOfficeHallArea",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateRangeConfig("brainbay.filter.shopArea.label", "brainbay.shopArea", true, false, false, false, "number"),

        generateRangeConfig(
            "brainbay.filter.horecaArea.label",
            "brainbay.horecaArea",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateRangeConfig(
            "brainbay.filter.groundArea.label",
            "brainbay.groundArea",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateRangeConfig(
            "brainbay.filter.parcelSize.label",
            "brainbay.parcelSize",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateRangeConfig(
            "brainbay.filter.salePrice.label",
            "brainbay.salePrice",
            true,
            false,
            false,
            false,
            "currency"
        ),

        generateRangeConfig(
            "brainbay.filter.parkingSpotsCovered.label",
            "brainbay.parkingSpotsCovered",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateRangeConfig(
            "brainbay.filter.parkingSpotsNonCovered.label",
            "brainbay.parkingSpotsNonCovered",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateSelectConfig(
            "brainbay.filter.isAuction.label",
            "brainbay.isAuction",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),

        generateSelectConfig(
            "brainbay.filter.isConfidential.label",
            "brainbay.isConfidential",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),

        generateSelectConfig(
            "brainbay.filter.isIndicationPublic.label",
            "brainbay.isIndicationPublic",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),

        generateSelectConfig(
            "brainbay.filter.horecaRegion.label",
            "brainbay.horecaRegion",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.horecaRegion.values."
        ),

        generateFuzzyConfig(
            "brainbay.filter.zoekterm.label",
            "location.zoekterm",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),
        generateSelectConfig(
            "brainbay.filter.ignoreLatLon.label",
            "brainbay.ignoreLatLon",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),
    ],
    brainbayAgricultureV2: [
        generateFuzzyConfig(
            "brainbay.filter.zoekterm.label",
            "zoekterm",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),

        generateFuzzyConfig(
            "brainbay.filter.postalCodeRadiusPostalCode.label",
            "postalCodeRadiusPostalCode",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),
        generateFuzzyConfig(
            "brainbay.filter.postalCodeRadiusRadius.label",
            "postalCodeRadiusRadius",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),

        generateSelectConfig(
            "brainbay.filter.saleOrRental.label",
            "saleOrRental",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.saleOrRental.values."
        ),
        generateSelectConfig(
            "brainbay.filter.companyType.label",
            "companyType",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.companyType.values."
        ),

        generateSelectConfig(
            "brainbay.filter.soilType.label",
            "soilType",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.soilType.values."
        ),

        generateSelectConfig(
            "brainbay.filter.province.label",
            "province",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.province.values."
        ),

        generateSelectConfig(
            "brainbay.filter.status.label",
            "status",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.status.values."
        ),

        generateDateTimeConfig("brainbay.filter.transactionDate.label", "transactionDate", true, false, false, false),

        generateRangeConfig("brainbay.filter.parcelSize.label", "parcelSize", true, false, false, false, "number"),
        generateRangeConfig("brainbay.filter.salePrice.label", "salePrice", true, false, false, false, "currency"),
        // rental price is possible, but we do not get it in the output
        // generateRangeConfig("brainbay.filter.rentalPrice.label", "rentalPrice", true, false, false, false, "currency"),

        generateDateTimeConfig("brainbay.filter.auctionDate.label", "auctionDate", true, false, false, false),

        generateSelectConfig(
            "brainbay.filter.ignoreLatLon.label",
            "ignoreLatLon",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),
        generateFuzzyConfig(
            "brainbay.filter.brainbayId.label",
            "brainbayId",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),
    ],
    brainbayBusinessV2: [
        generateFuzzyConfig(
            "brainbay.filter.zoekterm.label",
            "zoekterm",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),

        generateFuzzyConfig(
            "brainbay.filter.postalCodeRadiusPostalCode.label",
            "postalCodeRadiusPostalCode",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),
        generateFuzzyConfig(
            "brainbay.filter.postalCodeRadiusRadius.label",
            "postalCodeRadiusRadius",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),

        generateSelectConfig(
            "brainbay.filter.saleOrRental.label",
            "saleOrRental",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.saleOrRental.values."
        ),

        generateSelectConfig(
            "brainbay.filter.status.label",
            "status",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.status.values."
        ),
        generateDateTimeConfig("brainbay.filter.transactionDate.label", "transactionDate", true, false, false, false),

        generateSelectConfig(
            "brainbay.filter.companyType.label",
            "companyType",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.companyType.values."
        ),

        generateSelectConfig(
            "brainbay.filter.mainPurpose.label",
            "mainPurpose",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.mainPurpose.values."
        ),

        generateRangeConfig(
            "brainbay.filter.officeArea.label",
            "officeArea",
            true,
            true,
            "companyType",
            ["kantoorruimte"],
            "number"
        ),
        generateRangeConfig(
            "brainbay.filter.companyHallArea.label",
            "companyHallArea",
            true,
            false,
            "companyType",
            ["bedrijfsruimte"],
            "number"
        ),
        generateRangeConfig(
            "brainbay.filter.companyOfficeHallArea.label",
            "companyOfficeHallArea",
            true,
            false,
            "companyType",
            ["bedrijfsruimte"],
            "number"
        ),

        generateRangeConfig(
            "brainbay.filter.shopArea.label",
            "shopArea",
            true,
            false,
            "companyType",
            ["winkelruimte"],
            "number"
        ),

        generateRangeConfig(
            "brainbay.filter.horecaArea.label",
            "horecaArea",
            true,
            false,
            "companyType",
            ["horeca"],
            "number"
        ),
        generateSelectConfig(
            "brainbay.filter.horecaRegion.label",
            "horecaRegion",
            [],
            undefined,
            "companyType",
            ["horeca"],
            true,
            "brainbay.filter.horecaRegion.values."
        ),

        generateRangeConfig("brainbay.filter.groundArea.label", "groundArea", true, false, false, false, "number"),

        generateRangeConfig("brainbay.filter.parcelSize.label", "parcelSize", true, false, false, false, "number"),
        generateRangeConfig(
            "brainbay.filter.culturalInstitutionArea.label",
            "culturalInstitutionArea",
            true,
            false,
            "companyType",
            ["maatschappelijk vastgoed"],
            "number"
        ),
        generateRangeConfig(
            "brainbay.filter.educationalInstitutionArea.label",
            "educationalInstitutionArea",
            true,
            false,
            "companyType",
            ["maatschappelijk vastgoed"],
            "number"
        ),
        generateRangeConfig(
            "brainbay.filter.sportInstitutionArea.label",
            "sportInstitutionArea",
            true,
            false,
            "companyType",
            ["maatschappelijk vastgoed"],
            "number"
        ),
        generateRangeConfig(
            "brainbay.filter.healthcareInstitutionArea.label",
            "healthcareInstitutionArea",
            true,
            false,
            "companyType",
            ["maatschappelijk vastgoed"],
            "number"
        ),
        generateRangeConfig(
            "brainbay.filter.religiousInstitutionArea.label",
            "religiousInstitutionArea",
            true,
            false,
            "companyType",
            ["maatschappelijk vastgoed"],
            "number"
        ),
        generateRangeConfig(
            "brainbay.filter.leisureWellnessArea.label",
            "leisureWellnessArea",
            true,
            false,
            "companyType",
            ["maatschappelijk vastgoed"],
            "number"
        ),

        generateRangeConfig(
            "brainbay.filter.salePrice.label",
            "salePrice",
            true,
            false,
            "saleOrRental",
            ["sale"],
            "currency"
        ),
        generateRangeConfig(
            "brainbay.filter.rentalPrice.label",
            "rentalPrice",
            true,
            false,
            "saleOrRental",
            ["rental"],
            "currency"
        ),
        generateRangeConfig(
            "brainbay.filter.parkingSpotsCovered.label",
            "parkingSpotsCovered",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateRangeConfig(
            "brainbay.filter.parkingSpotsNonCovered.label",
            "parkingSpotsNonCovered",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateSelectConfig(
            "brainbay.filter.isAuction.label",
            "isAuction",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),

        generateSelectConfig(
            "brainbay.filter.isConfidential.label",
            "isConfidential",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),

        generateSelectConfig(
            "brainbay.filter.isIndicationPublic.label",
            "isIndicationPublic",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),
        generateFuzzyConfig(
            "brainbay.filter.brainbayId.label",
            "brainbayId",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),
        generateSelectConfig(
            "brainbay.filter.ignoreLatLon.label",
            "ignoreLatLon",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),
    ],
    brainbayResidentialV2: [
        generateFuzzyConfig(
            "brainbay.filter.zoekterm.label",
            "zoekterm",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),

        generateFuzzyConfig(
            "brainbay.filter.postalCodeRadiusPostalCode.label",
            "postalCodeRadiusPostalCode",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),
        generateFuzzyConfig(
            "brainbay.filter.postalCodeRadiusRadius.label",
            "postalCodeRadiusRadius",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),

        generateSelectConfig(
            "brainbay.filter.saleOrRental.label",
            "saleOrRental",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.saleOrRental.values."
        ),

        generateSelectConfig(
            "brainbay.filter.status.label",
            "status",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.status.values."
        ),

        generateDateTimeConfig("brainbay.filter.transactionDate.label", "transactionDate", true, false, false, false),

        generateSelectConfig(
            "brainbay.filter.objectType.label",
            "objectType",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.objectType.values."
        ),
        generateSelectConfig(
            "brainbay.filter.residentialObjectSubType.label",
            "residentialObjectSubType",
            [],
            false,
            "objectType",
            ["woonhuis"],
            true,
            "brainbay.filter.residentialObjectSubType.values."
        ),

        generateSelectConfig(
            "brainbay.filter.apartmentType.label",
            "apartmentType",
            [],
            false,
            "brainbay.objectType",
            ["appartement"],
            true,
            "brainbay.filter.apartmentType.values."
        ),

        generateSelectConfig(
            "brainbay.filter.otherRealEstate.label",
            "otherRealEstate",
            [],
            false,
            "brainbay.objectType",
            ["overig"],
            true,
            "brainbay.filter.otherRealEstate.values."
        ),

        generateSelectConfig(
            "brainbay.filter.constructionPeriod.label",
            "constructionPeriod",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.constructionPeriod.values."
        ),

        generateRangeConfig("brainbay.filter.livingArea.label", "livingArea", true, false, false, false, "number"),

        generateRangeConfig("brainbay.filter.parcelSize.label", "parcelSize", true, false, false, false, "number"),

        generateRangeConfig(
            "brainbay.filter.grossCapacity.label",
            "grossCapacity",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateRangeConfig(
            "brainbay.filter.salePrice.label",
            "salePrice",
            true,
            false,
            "saleOrRental",
            ["sale"],
            "currency"
        ),

        generateRangeConfig(
            "brainbay.filter.rentalPrice.label",
            "rentalPrice",
            true,
            false,
            "saleOrRental",
            ["rental"],
            "currency"
        ),

        generateRangeConfig("brainbay.filter.roomAmount.label", "roomAmount", true, false, false, false, "number"),

        generateRangeConfig(
            "brainbay.filter.buildingLayersAmount.label",
            "buildingLayersAmount",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateRangeConfig(
            "brainbay.filter.bedroomAmount.label",
            "bedroomAmount",
            true,
            false,
            false,
            false,
            "number"
        ),
        generateSelectConfig(
            "brainbay.filter.energyLabelFromTo.label",
            "energyLabelFromTo",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.energyLabel.values."
        ),

        generateSelectConfig(
            "brainbay.filter.isNewConstruction.label",
            "isNewConstruction",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),

        generateSelectConfig(
            "brainbay.filter.particularities.label",
            "particularities",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.particularities.values."
        ),

        generateSelectConfig(
            "brainbay.filter.isMonument.label",
            "isMonument",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),

        generateSelectConfig(
            "brainbay.filter.isRecreational.label",
            "isRecreational",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),
        generateSelectConfig(
            "brainbay.filter.hasBasement.label",
            "hasBasement",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),

        generateSelectConfig(
            "brainbay.filter.houseFacilities.label",
            "houseFacilities",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.houseFacilities.values."
        ),

        generateSelectConfig(
            "brainbay.filter.garageTypes.label",
            "garageTypes",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.garageTypes.values."
        ),

        generateSelectConfig(
            "brainbay.filter.mainGardenOrientation.label",
            "mainGardenOrientation",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.mainGardenOrientation.values."
        ),

        generateSelectConfig(
            "brainbay.filter.constructionType.label",
            "constructionType",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.constructionType.values."
        ),

        generateSelectConfig(
            "brainbay.filter.bathroomFacilities.label",
            "bathroomFacilities",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.bathroomFacilities.values."
        ),

        generateSelectConfig(
            "brainbay.filter.sites.label",
            "sites",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.sites.values."
        ),

        generateRangeConfig("brainbay.filter.gardenArea.label", "gardenArea", true, false, false, false, "number"),

        generateRangeConfig(
            "brainbay.filter.bathroomAmount.label",
            "bathroomAmount",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateRangeConfig(
            "brainbay.filter.garageCapacity.label",
            "garageCapacity",
            true,
            false,
            false,
            false,
            "number"
        ),

        generateDateTimeConfig("brainbay.filter.priceChangedDate.label", "priceChangedDate", true, false, false, false),

        generateFuzzyConfig(
            "brainbay.filter.brainbayId.label",
            "brainbayId",
            false,
            false,
            false,
            true,
            false,
            false,
            "text"
        ),
        generateSelectConfig(
            "brainbay.filter.ignoreLatLon.label",
            "ignoreLatLon",
            [],
            undefined,
            undefined,
            undefined,
            true,
            "brainbay.filter.boolean.values."
        ),
    ],
};

export default compViewForm;
